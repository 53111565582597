<div class="py-5 m-auto">
  <div class="help-container">
    <h2 tabindex="0">EID Security Compliance Tool Overview</h2>
    <section>
      <h3 tabindex="0">1. Overview</h3>
      <p tabindex="0">
        This site is to temporarily enable Enterprise IDs that are disabled due to workstation compliance issues. The site will allow you to search for an Enterprise ID and enable it if it is disabled. It is also possible to enable a softlocked device. Controls are in place to prevent enabling an EID that should not be enabled due to HR or other reasons.
      </p>
    </section>
    <section>
      <h3 tabindex="0">2. Report Issues</h3>
      <p tabindex="0">If you have any issues with the tool, please contact the <a href="mailto:IMS.Insights.Automation@accenture.com">IMS Insights Automation</a> Team and submit an incident to the assignment group APPSUP-APP-ESCTOOL.</p>
      Go to <a href="https://support.accenture.com/now/nav/ui/classic/params/target/incident.do%3Fsys_id%3D-1%26sysparm_query%3Dactive%3Dtrue%26sysparm_stack%3Dincident_list.do%3Fsysparm_query%3Dactive%3Dtrue" target='_blank' rel='noopener'>ServiceNow</a> to submit an incident.
      <ul>
        <li tabindex="0">Complete the following fields:
          <ul>
            <li tabindex="0">Caller: Your EID</li>
            <li tabindex="0">Location: Your Location (Should auto populate)</li>
            <li tabindex="0">Classification / Type: Application/Software > Functionality</li>
            <li tabindex="0">Application: EID Security Compliance Tool</li>
            <li tabindex="0">Channel: Self-service</li>
            <li tabindex="0">Owner Group: Your ServiceNow Group</li>
            <li tabindex="0">Assignment Group: APPSUP-APP-ESCTOOL</li>
            <li tabindex="0">Short Description: Issue with EID Security Compliance Tool</li>
            <li tabindex="0">Description: A description of the issue.</li>
          </ul></li>
      </ul>
    </section>
    <section>
      <h3 tabindex="0">3. Data Sources</h3>
      <ul>
        <li tabindex="0">MRDR
          <ul>
            <li tabindex="0">Employee Level</li>
            <li tabindex="0">Country</li>
            <li tabindex="0">City</li>
            <li tabindex="0">Location</li>
            <li tabindex="0">Supervisor</li>
            <li tabindex="0">Mobile Phone</li>
          </ul></li>
        <li tabindex="0">MyAccess
          <ul>
            <li tabindex="0">Account Status</li>
            <li tabindex="0">Password Status</li>
            <li tabindex="0">Personnel Number</li>
          </ul></li>
        <li tabindex="0">Active Directory
          <ul>
            <li tabindex="0">Passwordless Enabled</li>
          </ul></li>
        <li tabindex="0">PMT 2.0
          <ul>
            <li tabindex="0">Device Information</li>
          </ul></li>
      </ul>
    </section>
  </div>
</div>