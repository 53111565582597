import {
  NgModule,
  ModuleWithProviders,
  InjectionToken,
  Provider,
} from '@angular/core';
// tslint:disable-next-line:max-line-length
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
  BrowserAuthOptions,
  stubbedPublicClientApplication,
} from '@azure/msal-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../environments/environment';
// import { AppConfigService } from '../services/app-config.service';

export const REBAR_AUTH_GUARD = new InjectionToken<string>('REBAR_AUTH_GUARD');

// tslint:disable-next-line:max-line-length
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string): void {
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const msal = environment.config['msal'];
  let msalAuth;

  if (msal) {
    msalAuth = msal;
  } else {
    console.error('Configure msal in environment.ts ');
  }
  return new PublicClientApplication({
    auth: {
      clientId: environment.config.msal.clientId,
      authority: environment.config.msal.authority,
      redirectUri: environment.config.msal.redirectUri,
      postLogoutRedirectUri: "/"
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const msal = environment.config['msal'];

  const protectedResourceMap = new Map(
    msal['framework']['protectedResourceMap'] as Iterable<
      readonly [string, string[]]
    >
  );
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ["api://0ff84faf-ec39-4963-a6c0-a5c58ba65c96/Access.All"],
    },
    loginFailedRoute: '/login-failed',
  };
}

/*
  When running locally `npm run start:local` or `npm run test` or `npm run e2e`
  use mock values, to turn off security.  This is set at build time.
*/
interface IConfigMap {
  [key: string]: Provider[];
}
export const PROVIDERS: IConfigMap = {
  mock: [
    {
      provide: MSAL_GUARD_CONFIG,
      useValue: { client: 'mock', authority: 'mock' },
    },
    {
      provide: REBAR_AUTH_GUARD,
      useValue: emptyGuard,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useValue: {},
    },
    {
      provide: MSAL_INSTANCE,
      useValue: stubbedPublicClientApplication,
    },
    MsalService,
  ] as Provider[],
  app: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: REBAR_AUTH_GUARD, useClass: MsalGuard },
  ] as Provider[],
};

@NgModule({
  imports: [MsalModule],
})
export class RebarAuthModule {
  static forRoot(): ModuleWithProviders<RebarAuthModule> {
    return {
      ngModule: RebarAuthModule,
      providers: PROVIDERS[environment.providers],
    };
  }
}

/* eslint-disable @typescript-eslint/no-unused-vars */
export function emptyGuard(
  _route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot
): boolean {
  return true;
}
/* eslint-enable @typescript-eslint/no-unused-vars */
