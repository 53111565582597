import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AppComponent } from './root/app.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { AccessDeniedPageComponent } from './access-denied-page/access-denied-page.component'
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { AuthGuard } from './core/guards/auth.guard'
import { ApiDownPageComponent } from './api-down-page/api-down-page.component'
import { TimeoutComponent } from './timeout/timeout.component'
import { HelpComponent } from './help/help.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard',
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "no-access",
    component: AccessDeniedPageComponent
  },
  {
    path: "api-down",
    component: ApiDownPageComponent
  },
  {
    path: "timeout",
    component: TimeoutComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
