import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SpinnerOverlayComponent } from './core/spinner-overlay/spinner-overlay.component'
import { SpinnerOverlayService } from "./core/services/SpinnerOverlayService"
import { OverlayModule } from "@angular/cdk/overlay"
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './root/app.component'
import { FooterModule, NavigationBarModule, FormModule, DropdownModule, SplashScreenModule, InformationalModalModule } from 'bvd-modular-components';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RebarAuthModule } from './core/auth/rebar.auth.module';
import { MsalInterceptor, MsalRedirectComponent } from '@azure/msal-angular';
import { AccessDeniedPageComponent } from './access-denied-page/access-denied-page.component'
import { DashboardComponent } from './dashboard/dashboard.component'
import { ConfirmationDialogComponent } from './dashboard/confirmation-dialog/confirmation-dialog.component'
import { HelpComponent } from './help/help.component';
import { GuardsModule } from './core/guards/auth.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component'
import { ApiDownPageComponent } from './api-down-page/api-down-page.component'
import { TimeoutComponent } from './timeout/timeout.component'
import { NgIdleModule } from '@ng-idle/core';
import { DetailedDevicesTableComponent } from './detailed-devices-table/detailed-devices-table.component';
import { EnableEidModalComponent } from './enable-eid-modal/enable-eid-modal.component'

@NgModule({ declarations: [
        AppComponent,
        SpinnerOverlayComponent,
        AccessDeniedPageComponent,
        DashboardComponent,
        ConfirmationDialogComponent,
        HelpComponent,
        PageNotFoundComponent,
        ApiDownPageComponent,
        TimeoutComponent,
        DetailedDevicesTableComponent,
        EnableEidModalComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [OverlayModule,
        BrowserModule,
        AppRoutingModule,
        RebarAuthModule.forRoot(),
        NgIdleModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        FooterModule, NavigationBarModule, FormModule, DropdownModule, SplashScreenModule, InformationalModalModule,
        GuardsModule], providers: [
        SpinnerOverlayService,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
