import { Component, OnInit, OnDestroy } from '@angular/core'
import { Validators } from '@angular/forms'
import { Subscription } from 'rxjs'
import { retryWhen } from 'rxjs/operators'
import { DataService } from '../core/services/data.service'
import { SpinnerOverlayService } from '../core/services/SpinnerOverlayService'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component'
import { environment } from '../../environments/environment'
import { RebarAuthService } from '../core/auth/rebar.auth.service'
import { Router } from '@angular/router'
import { complianceFlags } from '../data/complianceFlags'
import { DetailedDevicesTableComponent } from '../detailed-devices-table/detailed-devices-table.component'
import { EnableEidModalComponent } from '../enable-eid-modal/enable-eid-modal.component'
import { getPendingApprovalObject, emptyObject, checkConditionalAccess, retryStrategy } from '../core/utilities/helper-functions'
import { Device } from '../models/device'
import { checkAuthorized } from '../core/utilities/auth-util'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  loggedInUser: any = { eid: '' }
  queued: boolean = false
  placeholder: string = 'search eids to enable'
  profilePicture: any
  selectedEid: any = { devices: [] }
  errorMessage: string = ''
  displaySpinner: boolean = false
  retreivingCA: boolean = false
  showGLRMessage: boolean = false
  checkedADGroup: boolean = false
  demoLegend: boolean = false
  getDevicesError: boolean = false
  getDevicesCAError: boolean = false
  deviceNotFound: boolean = false
  loadingDevices: boolean = false
  isProd: boolean = environment.environment === 'prod'
  instructions: any
  isVisible: boolean = true

  _search: string = ''
  get search(): string {
    return this._search
  }
  set search(value: string) {
    this._search = value
  }

  checkConditionalAccess = checkConditionalAccess
  emptyObject = emptyObject

  eidDetailsSubscription: Subscription = new Subscription()
  userSubscription: Subscription = new Subscription()
  profilePictureSubscription: Subscription = new Subscription()
  modalSubscription: Subscription = new Subscription()
  deviceSubscription: Subscription = new Subscription()

  complianceFlags = complianceFlags

  constructor(private router: Router, private dataService: DataService, private modalService: NgbModal, private spinnerOverlayService: SpinnerOverlayService, private rebarAuthService:RebarAuthService) { }

  ngOnInit(): void {
    this.instructions = [
      "1. Search below for the employee whose conditional access you would like to remove",
      "2. Validate employee information (name, location, level, etc.).",
      "3. Click the Enable button placed against each device name that has conditional access to remove conditional access (Soft CA, Hard CA, Device CA). If the EID is disabled the reason will be mentioned on the right side of the tool. Copy the text to clipboard and communicate to the employee.",
      "4. Inform the employee of the updated status. <i>note: enabling the EID or a device will take up to an hour to propagate</i>",
      "5. This tool cannot be used to remove conditional access for users from Germany/Austria",
    ]
    this.spinnerOverlayService.show() // only show after authStatus so it only displays after the splash screen
    this.placeholder = environment.environment === 'prod' ? 'search eids to enable' : 'search \'test.disabled\', \'test.locked\', or \'test.disabledAndLocked\' to test'
    this.userSubscription = this.dataService.userSubject.subscribe({
      next: (res: any) => {
        this.setLoggedInUser(res)
        this.spinnerOverlayService.hide()
      }, error: (error: any) => {
        console.error(error)
        this.spinnerOverlayService.hide()
      }
    })

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' && this.demoLegend) this.demoLegend = false
    })
  }

  setVisible(isVisible: boolean) {
    this.isVisible = isVisible
  }

  handleKeydown(event: KeyboardEvent, isVisible: boolean): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault()
      this.setVisible(isVisible)
    }
  }

  ngOnDestroy(): void {
    if (this.eidDetailsSubscription) this.eidDetailsSubscription.unsubscribe()
    if (this.userSubscription) this.userSubscription.unsubscribe()
    if (this.profilePictureSubscription) this.profilePictureSubscription.unsubscribe()
    if (this.modalSubscription) this.modalSubscription.unsubscribe()
    if (this.deviceSubscription) this.deviceSubscription.unsubscribe()
  }

  setLoggedInUser(newUser: any): void {
    this.loggedInUser = newUser
    this.loggedInUser.level = parseInt(this.loggedInUser.level)
    if (this.loggedInUser.country) this.showGLRMessage = this.checkASGR(this.loggedInUser.country)
    this.checkedADGroup = this.loggedInUser.isInServiceDesk
    checkAuthorized(this.loggedInUser, this.router)
  }

  checkASGR(country: string): boolean {
    return (country.length > 0 && ['austria', 'germany', 'russia'].includes(country.toLocaleLowerCase()))
  }

  selectEid(): void {
    this.errorMessage = ''
    this.queued = false
    this.displaySpinner = true
    this.getEidDetails(this._search)
  }

  getEidDetails(enterpriseID: string): void {
    //reset all values and error flags
    this.selectedEid = { devices: [] }
    this.profilePicture = null
    this.getDevicesError = false
    this.getDevicesCAError = false
    this.deviceNotFound = false
    enterpriseID = enterpriseID.toLocaleLowerCase()
    this.eidDetailsSubscription = this.dataService.getEid(enterpriseID).subscribe({
      next: (res: any) => {
        if (!this.checkASGR(res.country)) {
          this.selectedEid = res
          this.queued = this.isQueued()
          if (enterpriseID.startsWith('test.')) {
            this.profilePicture = null
            this.displaySpinner = false
          } else {
            this.getProfilePicture(enterpriseID) // gets profile picture then sets displaySpinner to false
          }
          this.selectedEid.devices = this.selectedEid.devices.map((device: any) => { device.attribute = this.formatDeviceAttribute(device.attribute); return device })
        } else {
          this.displaySpinner = false
          this.errorMessage = "Cannot view or enable eids located in Austria or Germany."
          this.eidDetailsSubscription.unsubscribe()
        }
      }, error: (error: any) => {
        console.error(error)
        this.displaySpinner = false
        if (error && error.error && error.error.error === "Only test EIDs are allowed in this environment") {
          this.errorMessage = "Only test IDs are allowed in this environment."
        } else {
          this.errorMessage = "Cannot find eid. Please try again later."
        }
        this.eidDetailsSubscription.unsubscribe()
      }
    })
  }

  retryCheckDevicesCA(): void {
    this.getDevicesCAError = false
    this.selectedEid.devices = this.selectedEid.devices.map((device: any) => { device.attribute = null; return device })
    this.checkDevicesCA()
  }

  checkDevicesCA(): void {
    this.retreivingCA = true
    let devices = this.selectedEid.devices.map((device: any) => device.deviceSerialNumber)
    let checkDeviceAttributeSubscription: Subscription = this.dataService.getDevicesAttributes(devices).pipe(
      retryWhen(error => retryStrategy(error))
    ).subscribe({
      next: (res: any) => {
        this.retreivingCA = false
        if(Array.isArray(res)) {
          res.forEach((device: any) => {
            let deviceFound = this.selectedEid.devices.find((d: any) => d.deviceSerialNumber.toLocaleLowerCase() === device.serialNumber.toLocaleLowerCase())
            if (deviceFound) {
              deviceFound.attribute = this.formatDeviceAttribute(device.attribute)
              deviceFound.id = device.deviceId
            }
          })
        } else if (res.error) {
          this.selectedEid.devices[0].attribute = { error: true }
          this.selectedEid.devices[0].attribute = { error: true }
          this.getDevicesCAError = true
        }
        this.selectedEid.devices.forEach((device: any) => {
          if (device.attribute === null) {
            device.attribute = "Not found in Azure"
            this.deviceNotFound = true
          }
        })
        checkDeviceAttributeSubscription.unsubscribe()
      }, error: (error: any) => {
        console.error(error)
        this.retreivingCA = false
        this.getDevicesCAError = true
        checkDeviceAttributeSubscription.unsubscribe()
      }
    })
  }

  formatDeviceAttribute(attribute: string): string {
    if (attribute === null) {
      attribute = 'No CA'
    }
    if (attribute) {
      let attributeLower: string = attribute.toString().toLocaleLowerCase()
      if (attributeLower.includes('removeca')) {
        attribute = 'Remove CA'
      } else if (attributeLower.includes('softca')) {
        attribute = 'Soft CA'
      } else if (attributeLower.includes('hardca')) {
        attribute = 'Hard CA'
      } else if (attributeLower.includes('eidca')) {
        attribute = 'Device CA'
      } else if (attributeLower.includes('disableeidca')) {
        attribute = 'Device CA'
      } else if (attributeLower.includes('error')) {
        attribute = 'Error'
        this.getDevicesCAError = true
      } else {
        attribute = 'No CA'
      }
    }
    return attribute
  }

  getProfilePicture(enterpriseID: string): void {
    this.profilePictureSubscription = this.dataService.getProfilePicture(enterpriseID).subscribe({
      next: (res: any) => {
        console.log(res)
        if (res && res.type === 'image/jpeg') {
          this.setProfilePictureFromBlob(res)          
        } else {
          this.profilePicture = null
        }
        this.displaySpinner = false
        this.eidDetailsSubscription.unsubscribe()
        this.profilePictureSubscription.unsubscribe()
      }, error: (error: any) => {
        console.error(error)
        this.profilePicture = null
        this.displaySpinner = false
        this.eidDetailsSubscription.unsubscribe()
        this.profilePictureSubscription.unsubscribe()
      }
    })
  }

  setProfilePictureFromBlob(image: Blob): void {
    let reader = new FileReader()
    reader.addEventListener("load", () => {
     this.profilePicture = reader.result
    }, false)
    if (image) reader.readAsDataURL(image)
  }

  enableDevice(device: any, eid: string): void {
    if (this.selectedEid.isEnabled === false) {
      return
    }
    const modalRef = this.modalService.open(ConfirmationDialogComponent, {size: 'lg', backdrop: 'static'})
    const deviceConfirmationData = {
      formName: "Device Confirmation",
      icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" height=\"38px\" viewBox=\"0 0 24 24\" width=\"38px\" fill=\"#7500C0\"><path d=\"M0 0h24v24H0z\" fill=\"none\"/><path d=\"M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z\"/></svg>",
      // directions: "Confirm that you would like to enable this user's device.",
      rows: [
        [{
          name: "checkbox",
          label: "I understand the repercussions and would like to enable Device " + device.computerName + ".*",
          inputType: "checkbox",
          disabled: false,
          validators: [Validators.required],
          inline: true,
          reverse: false,
        }],
        [{
          name: "incident",
          label: "Incident Number associated with this enablement.*",
          inputType: "text",
          validators: [Validators.required, Validators.pattern('[iI][nN][cC][0-9]+'), Validators.maxLength(11)],
          inline: true
        }]
      ],
      buttons: [
        {
          text: "Cancel",
          type: "secondary",
          subscription: null,
          callback: (() => { modalRef.close() }).bind(this)
        },
        {
          text: "Enable Device",
          presentParticiple: "submitting",
          type: "primary",
          // subscription: (() => this.dataService.removeConditionalAccess(device.AAD_DeviceID, device.computerName, eid, '')),
          callback: (() => {
            device.attribute = "Remove CA"
            modalRef.close()
          }).bind(this)
        }
      ],
      deviceID: device.AAD_DeviceID,
      deviceName: device.computerName,
      eid: eid
    }
    modalRef.componentInstance.metaData = deviceConfirmationData
  }

  enable(): void {
    const modalRef = this.modalService.open(EnableEidModalComponent, {size: 'lg', backdrop: 'static'})
    const confirmationData = {
      selectedEid: this.selectedEid,
      loggedInUser: this.loggedInUser,
      checkedADGroup: this.checkedADGroup,
    }
    modalRef.componentInstance.metaData = confirmationData
    this.modalSubscription = modalRef.componentInstance.next.subscribe((confirmedDevices: any) => {
      modalRef.close()
      // if ((this.loggedInUser.level <= 6 || this.checkedADGroup) || !emptyObject(this.selectedEid.pendingApprovals)) { this.queued = true }
      if ((this.checkedADGroup) || !emptyObject(this.selectedEid.pendingApprovals)) { this.queued = true }
      else Object.assign(this.selectedEid.pendingApprovals, getPendingApprovalObject(this.selectedEid, this.loggedInUser, confirmedDevices))
      this.modalSubscription.unsubscribe()
    })
  }

  back(): void {
    this.queued = false
  }

  getDirectionsButtonText(): string {
    // if (this.loggedInUser.level <= 6 || this.checkedADGroup) return 'enable'
    if (this.checkedADGroup) return 'enable'
    else if (this.hasValue(this.selectedEid.pendingApprovals, this.loggedInUser.eid)) return 'approval 2'
    else return 'approval 1'
  }

  getFirstButtonText(): string {
    if (this.selectedEid.isEnableable) {
      if (!emptyObject(this.selectedEid.pendingApprovals)) {
        return this.selectedEid.pendingApprovals.approvedBy + ' approved'
      } else {
        return 'approval 1'
      }
    } else {
      return 'ineligible to be enabled'
    }
  }

  getSecondButtonText(): string {
    if (this.selectedEid.isEnableable) {
      if (this.hasValue(this.selectedEid.pendingApprovals, this.loggedInUser.eid)) {
        return 'waiting second approval'
      } else {
        return 'approval 2'
      }
    } else {
      return 'ineligible to be enabled'
    }
  }

  isQueued(): boolean {
    return (this.selectedEid && !this.selectedEid.isEnabled && this.selectedEid.lastEnablementQueuedOn && (!emptyObject(this.selectedEid.lastEnablementQueuedOn) && this.isRecent(this.selectedEid.lastEnablementQueuedOn.enablementQueuedOn)))
  }

  isRecent(enablementQueuedOn: Date): boolean {
    let enablementQueuedOnInTime = new Date(enablementQueuedOn).getTime()
    let diff = Math.abs(Date.now()- enablementQueuedOnInTime)
    let minutes = Math.floor((diff/1000)/60)
    return (minutes < 60)
  }

  hasValue(obj: any, value: any): boolean {
    return obj && value && Object.values(obj).includes(value)
  }

  getComplianceFlag(label: string) {
    return this.complianceFlags.find(cf => cf.label === label) || { order: -1, label: '', message: '' }
  }

  getMessage(label: string): string {
    let message = this.getComplianceFlag(label).message || ''
    return message
  }

  copyToClipboard(className: string): void {
    const copyElement = document.getElementsByClassName(className)[0] || ''
    let copyText: string = copyElement.textContent || ''
    let aCollection = copyElement.getElementsByTagName('a')
    if (aCollection.length > 0) { // replace any link text with actual link
      for (let ai = 0; ai < aCollection.length; ai++) {
        const innerText: string = aCollection[ai].innerText ?? ''
        const href: string = aCollection[ai].href ?? ''
        copyText = copyText.replace(innerText, href)
      }
    }
    if (this.selectedEid.complianceFlag && this.checkedADGroup) {
      copyText = copyText.replace('because', 'This EID has been disabled because of the flag: ')
      copyText = copyText.replace('---', '. ')
    }
    navigator.clipboard.writeText(copyText)
  }

  copyDemoEidtoClipboard(eid: string): void {
    navigator.clipboard.writeText(eid)
    this.search = eid
    this.selectEid()
  }






  // Unuesed functions
  openDetailedDeviceView(): void {
    const modalRef = this.modalService.open(DetailedDevicesTableComponent, {size: 'xl', backdrop: 'static'})
    modalRef.componentInstance.devices = this.selectedEid.devices || []
  }
  // Unused
  retryGetDevicesForEid(eid: string): void {
    this.getDevicesError = false
    eid = eid.toLocaleLowerCase() || eid
    this.getDevicesForEid(eid)
  }
  // Unused
  getDevicesForEid(eid: string): void {
    this.loadingDevices = true
    this.deviceSubscription = this.dataService.getDevicesFor(eid).pipe(
      retryWhen(error => retryStrategy(error))
    ).subscribe({
      next: (res: any) => {
        this.loadingDevices = false
        if (res.error) this.getDevicesError = true
        else if (res && res.length > 0) {
          this.selectedEid.devices = []
          res.forEach((device: any) => this.selectedEid.devices.push(new Device(device.id, device.azureActiveDirectoryDeviceId, device.pcmodel, device.computerName, device.serialNumber, null, null)))
          this.checkDevicesCompliance()
        } else {
          this.selectedEid.devices = []
        }
        this.deviceSubscription.unsubscribe()
      }, error: (error: any) => {
        console.error(error)
        this.getDevicesError = true
        this.deviceSubscription.unsubscribe()
        this.loadingDevices = false
      }
    })
  }
  // Unused
  checkDevicesCompliance(): void {
    // run this.dataService.checkDevice() on each of the devices in this.selectedEid.devices
    if (this.selectedEid.devices.length > 0) {
      this.selectedEid.devices.forEach((device: Device) => {
        this.checkDeviceCompliance(this.loggedInUser)
        this.checkDevicePMTCompliance(device)
      })
    }
  }
  // Unused
  retryDeviceCompliance(device: Device): void {
    device.attribute = null
    this.checkDeviceCompliance(device)
  }
  // Unused
  retryDevicePMTCompliance(device: Device): void {
    device.Compliance = null
    this.checkDevicePMTCompliance(device)
  }
  // Unused
  checkDeviceCompliance(device: Device): void {
    let checkDeviceSubscription: Subscription = this.dataService.checkDevice(device.azureActiveDirectoryDeviceId).pipe(
      retryWhen(error => retryStrategy(error))
    ).subscribe({
      next: (res: any) => {
        res.attribute = this.formatDeviceAttribute(res.attribute)
        checkDeviceSubscription.unsubscribe()
      }, error: (error: any) => {
        console.error(error)
        device.attribute = { error: true }
        checkDeviceSubscription.unsubscribe()
      }
    })
  }

  // Unused
  // a function checkDevicePMTCompliance(device: Device) that checks the device's PMT compliance by hitting the /checkPMTCompliance() endpoint
  checkDevicePMTCompliance(device: Device): void {
    let checkDevicePMTSubscription: Subscription = this.dataService.checkPMTCompliance(device.computerName).pipe(
      retryWhen(error => retryStrategy(error))
    ).subscribe({
      next: (res: any) => {
        if (res.error) {
          device.Compliance = { error: true }
        } else if (res) {
          device.Compliance = res['PMT Compliance']
        }
        checkDevicePMTSubscription.unsubscribe()
      }, error: (error: any) => {
        console.error(error)
        device.Compliance = { error: true }
        checkDevicePMTSubscription.unsubscribe()
      }
    })
  }
}