import { Component, OnInit, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'
import { DataService } from '../core/services/data.service'
import { Router } from '@angular/router'
import { checkAuthorized  } from '../core/utilities/auth-util'
@Component({
  selector: 'app-api-down-page',
  templateUrl: './api-down-page.component.html',
  styleUrls: ['./api-down-page.component.scss']
})
export class ApiDownPageComponent implements OnInit, OnDestroy {
  userSubscription: Subscription = new Subscription()

  constructor(private router: Router, private dataService: DataService) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
}
