<div class="modal-header d-flex mb-2">
  <h5 class="modal-title d-flex p-2">
    <div class="svg operations-border icon-margin" [innerHTML]="svg"></div>
    <span>{{metaData.formName}}</span>
  </h5>
  <button type="button" class="close p-2 ms-auto" (click)="activeModal.dismiss('x clicked')" aria-label="Close">
    <span aria-hidden="true"><svg alt="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#A100FF"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
  </button>
</div>
<form [formGroup]="formGroup">
  <div class="modal-body">
    <div *ngIf="metaData.directions" class="alert alert-secondary" role="alert" [innerHTML]="metaData.directions"></div>
    <div *ngFor="let row of metaData.rows" class="row form-row">
      <ng-container *ngFor="let customField of row">
        <div class="col" *ngIf="customField.inputType==='checkbox'">
          <div class="form-group" [ngClass]="{ 'inline' : customField.inline, 'reverse': customField.reverse, 'checkbox-text': customField.inputType === 'checkbox' }">
            <span class="form-text">
              <label for="{{customField.label}}">{{customField.label}}</label>
              <p class="subtext">{{customField.subtext}}</p>
            </span>
            <div class="form-group d-flex flex-column" attr.aria-label="{{customField.name}}" tabindex="0" role="button" (keydown.enter)="select(customField.name, true)">
              <input type="checkbox" formControlName="{{customField.name}}" [(ngModel)]="isAccepted" name="{{customField.name}}" autocomplete="off" tabindex="-1">
            </div>
          </div>
        </div>
        <div class="col" *ngIf="customField.inputType==='text'">
          <div class="form-group" [ngClass]="{ 'inline' : customField.inline, 'reverse': customField.reverse, 'checkbox-text': customField.inputType === 'checkbox' }">
            <span class="form-text">
              <label for="{{customField.label}}">{{customField.label}}</label>
              <p class="subtext">{{customField.subtext}}</p>
            </span>
              <input type="text" class="form-control short" formControlName="{{customField.name}}" [(ngModel)]="incident" name="{{customField.name}}" autocomplete="off" maxlength="11">
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="alert alert-warning alert-dismissible" [hidden]="!submissionError || updated || updating" role="alert">
    Failed to remove conditional access.  Please try again later.
    <button type="button" class="close" data-dismiss="alert" aria-label="dismiss">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="isIncidentFieldInvalid()" class="alert alert-warning alert-dismissible">
    <span>Incident Number must start with 'INC' followed by 8 numbers.</span>
  </div>  
  <div class="alert alert-success" *ngIf="updated && !updating && !submissionError" role="alert">
    <span *ngIf="metaData.successMessage">{{ metaData.successMessage }}</span>
    <span *ngIf="!metaData.successMessage">Success! {{ currentButton.text }}ed the {{ metaData.formName }}!</span>
  </div>
  <div class="modal-footer">
    <ng-container *ngFor="let button of metaData.buttons">
      <button type="button" class="btn" [ngClass]="{'btn-primary': button.type === 'primary', 'btn-secondary': button.type === 'secondary'}" attr.aria-label="{{button.text}}" (click)="submit(button)" [disabled]="!formGroup.valid && button.text !== 'Cancel' && button.text !== 'Close' || (button.text === 'Enable Device' && !isAccepted)">
        <span *ngIf="!button.updating">{{button.text}}</span>
        <span *ngIf="button.updating">{{button.presentParticiple || button.text + 'ing'}}</span>
      </button>
    </ng-container>
  </div>
</form>