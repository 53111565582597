<div class="modal-header">
    <h5 class="modal-title d-flex align-items-end">
        <svg class="operations-border icon-margin" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="36px" viewBox="0 0 24 24" width="36px" fill="#A100FF"><g><rect fill="none" height="24" width="24"/><path d="M19,7H9C7.9,7,7,7.9,7,9v10c0,1.1,0.9,2,2,2h10c1.1,0,2-0.9,2-2V9C21,7.9,20.1,7,19,7z M19,9v2H9V9H19z M13,15v-2h2v2H13z M15,17v2h-2v-2H15z M11,15H9v-2h2V15z M17,13h2v2h-2V13z M9,17h2v2H9V17z M17,19v-2h2v2H17z M6,17H5c-1.1,0-2-0.9-2-2V5 c0-1.1,0.9-2,2-2h10c1.1,0,2,0.9,2,2v1h-2V5H5v10h1V17z"/></g></svg>
        <!-- <svg class="operations-border icon-margin" xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="-2 -2 28 28" fill="#A100FF"><path d="M0 0h24v24H0z" fill="none"/><path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg> -->
        <span>Detailed Device Table</span>
    </h5>
    <button type="button" class="close" (click)="activeModal.close('clear')" aria-label="Close">
      <span aria-hidden="true"><svg alt="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#A100FF"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
    </button>
</div>
<div class="modal-body">
    <table id="detailed-device-table">
        <tr class="bold">
            <th>Device Model</th>
            <th>Device Name</th>
            <th>Asset ID</th>
            <!-- <th>Serial Number</th>
            <th>Asset Tag</th> -->
            <th>Manufacturer</th>
            <th>Soft Conditional Access</th>
            <th>Hard Conditional Access</th>
        </tr>
        <tr *ngFor="let device of devices">
            <td>{{device.model | lowercase}}</td>
            <td>{{device.computerName | lowercase}}</td>
            <td>{{device.id | lowercase}}</td>
            <!-- <td>{{device.serialNumber | lowercase}}</td>
            <td>{{device.assetTag | lowercase}}</td> -->
            <td>{{device.manufacturer | lowercase}}</td>
            <td class="d-flex justify-content-center">
                <svg class="tiny-spinner" *ngIf="device.softConditionalAccess === null">
                    <circle cx="10" cy="10" r="6"></circle>
                  </svg>
                <span data-toggle="tooltip" data-placement="top" title="Has Soft Conditional Access"><svg *ngIf="device.softConditionalAccess === true" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px" fill="#4A8244"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" /></svg></span>
                <span data-toggle="tooltip" data-placement="top" title="Does not have Soft Conditional Access"><svg *ngIf="device.softConditionalAccess === false" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -1 24 24" width="24px" fill="#E32739"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg></span>
            </td>
            <td style="text-align: center;">
                <svg class="tiny-spinner" *ngIf="device.hardConditionalAccess === null">
                    <circle cx="10" cy="10" r="6"></circle>
                  </svg>
                <span data-toggle="tooltip" data-placement="top" title="Has Hard Conditional Access"><svg *ngIf="device.hardConditionalAccess === true" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px" fill="#4A8244"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" /></svg></span>
                <span data-toggle="tooltip" data-placement="top" title="Does not have Hard Conditional Access"><svg *ngIf="device.hardConditionalAccess === false" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -1 24 24" width="24px" fill="#E32739"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg></span>
            </td>
        </tr>
      </table>
        <ng-container *ngFor="let device of devices">
            <div class="alert alert-danger alert-relative box-shadow" style="margin-top: 1rem;" *ngIf="(device.hardConditionalAccess && device.hardConditionalAccess.error) || (device.softConditionalAccess && device.softConditionalAccess.error)">
                <svg class="svg-shadow pointer" (click)="retryDeviceCompliance(device)" data-toggle="tooltip" data-placement="top" title="Retry Check Device Compliance" xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#721c24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6 10c0 3.31-2.69 6-6 6s-6-2.69-6-6h2c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4v3L8 7l4-4v3c3.31 0 6 2.69 6 6z" fill-rule="evenodd"/></svg>
                | Unable to retrieve conditional access statuses for {{ device.computerName }}.
            </div>
        </ng-container>
</div>
<!-- <div class="modal-footer"></div> -->