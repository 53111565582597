import { environment } from '../../../environments/environment'
import { Router } from '@angular/router'
import { of } from 'rxjs'

export function checkAuthorized(user: any, router?: Router): boolean {
  if (checkForSpeciallyAuthorizedUser(user) || user.isInServiceDesk) { //} || ((user.level && (user.level === 'Accenture Leadership' || parseInt(user.level) <= environment.minimumLevel)))) {
    return true
  } else {
    if (router) router.navigate(['/no-access'])
    return false
  }
}

export function checkForSpeciallyAuthorizedUser(user: any): boolean {
  const eid = user?.eid || ''
  if (environment.environment === 'prod') {
    return false
  } else return (['chris.grenier', 'a07047dirphub-auto01', 'a0416ods_ntestuser', 'christopher.jeresano'].includes(eid.toLocaleLowerCase()))
}

export function checkIfTokenIsNotExpired(token: any): boolean {
  if (token) {
    const expiresOn = +token.expiresOn
    const currentUnixTime = Math.floor(Date.now() / 1000)
    return (expiresOn > currentUnixTime)
  } else return false
}

export function convertUnix(input: any): Date {
  return new Date(input * 1000)
}

export function getToken(tokenName: string, scopes?: string[]): any {
  for (let item in localStorage) {
    let token: any = localStorage.getItem(item)
    if (token) try {
      token = JSON.parse(token) || null
      if (token && token.credentialType === tokenName) {
        if (scopes) {
          for (let scope of scopes) {
            if (token.target.toLocaleLowerCase().includes(scope.toLocaleLowerCase())) return token
          }
        } else return token
      }
    } catch(e) {
      // console.error(e)
    }
  }
  return null
}

export function removeTokens(tokenNames: string): void {
  for (let item in localStorage) {
    let token: any = localStorage.getItem(item)
    if (token) try {
      let parsedToken = JSON.parse(token)
      if (parsedToken && tokenNames.includes(parsedToken.credentialType)) {
        console.warn('removing ' + parsedToken.credentialType)
        localStorage.removeItem(item)
      }
    } catch (e) { /* console.error(e) */ }
  }
}

export function getTokenSecret(tokenName: string): any {
  let token = getToken(tokenName)
  if (token && token.secret) {
    return token.secret
  } else return null
}

export function parseJWTToken(token: any): any {
  if (token) {
    let base64Credentials = token.split('.')[1]
    let credentials = atob(base64Credentials)
    return JSON.parse(credentials)
  } else return null
}

export function helpCatchError(error: any): any {
  console.error(error)
  return of(null)
}

export function catchErrorAndReroute(error: any, router: Router): any {
  router.navigate(['api-down'])
  helpCatchError(error)
}
