import { delay, take, concatMap } from 'rxjs/operators'
import { throwError } from 'rxjs'

export function getPendingApprovalObject(selectedEid: any, loggedInUser: any, devices?: any): any {
    let pendingApprovalObject: any = {
      "eid": selectedEid.eid,
      "approverLevel": loggedInUser.level,
      "approvedBy": loggedInUser.eid,
      "disabledReason": (selectedEid.complianceFlag || ''),
      "approvedOn": new Date(),
    }
    if (devices && devices.length > 0) pendingApprovalObject.devices = devices
    return pendingApprovalObject
}

export function emptyObject(obj: any): boolean {
  return JSON.stringify(obj) === '{}'
}

// a function to check if any device does not have softConditionalAccess and/or hardConditionalAccess
export function checkConditionalAccess(devices: any): boolean {
    return (devices && devices.some((device: any) => device.softConditionalAccess === false || device.hardConditionalAccess === false))
}

export function retryStrategy(error: any): any { // wait 1.5s upon error and then re-try
  return error.pipe(
    delay(15000),
    take(2),
    concatMap(e => throwError(e))
  )
}