<div id="master-container">
  <div id="main-container">
    <div id="demo-legend"  (click)="demoLegend = !demoLegend" *ngIf="!isProd" tabindex="0">
      <span class="legend-icon-container"  data-toggle="tooltip" data-placement="top" title="Display Legend for Demo">
        <svg class="white-border legend-icon svg-shadow" tabindex="0"role="button" (keydown.enter)="demoLegend = !demoLegend" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="40" viewBox="0 0 24 24" width="40" fill="#7500C0"><g><path d="M0,0h24v24H0V0z" fill="none"/><path d="M11,7h2v2h-2V7z M11,11h2v6h-2V11z M12,2C6.48,2,2,6.48,2,12s4.48,10,10,10s10-4.48,10-10S17.52,2,12,2z M12,20 c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S16.41,20,12,20z"/></g></svg>
      </span>
      <div *ngIf="demoLegend" id="legend" class="legend-overlay pointer">
        <!-- <h4 class="legend-item" style="margin-top: .66rem;">legend: </h4> -->
        <div class="legend-section">
          <div class="legend-item row" id="inverted">
            <span class="col-3 bold">DEMO EID</span>
            <span class="dash col-1"></span>
            <span class="col bold">DESCRIPTION</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.enabled')">
            <span class="col-3 bold">test.enabled</span>
            <span class="dash col-1"></span>
            <span class="col">Enabled</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.enabledWithConditionalAccess')">
            <span class="col-3 bold">test.enabledWithConditionalAccess</span>
            <span class="dash col-1"></span>
            <span class="col">Enabled EID with Conditionally locked devices</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.unlocked')">
            <span class="col-3 bold">test.unlocked</span>
            <span class="dash col-1"></span>
            <span class="col">Unlocked</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.locked')">
            <span class="col-3 bold">test.locked</span>
            <span class="dash col-1"></span>
            <span class="col">Locked</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabledAndLocked')">
            <span class="col-3 bold">test.disabledAndLocked</span>
            <span class="dash col-1"></span>
            <span class="col">Disabled and Locked</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabled')">
            <span class="col-3 bold">test.disabled</span><!--with Compliance Flag 22: GAIT Workstation Non-Polling-->
            <span class="dash col-1"></span>
            <span class="col">Disabled due to MyEquipment issue.</span>
          </div>
          <!-- <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabled2')">
            <span class="col-3 bold">test.disabled2</span>
            <span class="dash col-1"></span>
            <span class="col">Disabled waiting on 2nd Approval</span>
          </div> -->
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabled3')">
            <span class="col-3 bold">test.disabled3</span> <!--with Compliance Flag 43: Must upgrade FNMS agent-->
            <span class="dash col-1"></span>
            <span class="col">Disabled asking user to "Please contact your local technology support."</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabled4')">
            <span class="col-3 bold">test.disabled4</span><!--with Compliance Flag 18: Non Compliance - HR Services - Security Updates-->
            <span class="dash col-1"></span>
            <span class="col">Disabled asking user to reach out to HR.</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabled5')">
            <span class="col-3 bold">test.disabled5</span><!--with Compliance Flag 19: Security Incident not Remediated, Contact IS.Monitoring.T1-->
            <span class="dash col-1"></span>
            <span class="col">Disabled asking user to contact IS.Monitoring.T1</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabled6')">
            <span class="col-3 bold">test.disabled6</span><!--with Compliance Flag 23: India exit process-->
            <span class="dash col-1"></span>
            <span class="col">Disabled user in India Exit Process</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.disabled7')">
            <span class="col-3 bold">test.disabled7</span><!--with Compliance Flag 22: In Leavers group-->
            <span class="dash col-1"></span>
            <span class="col">Disabled user in Leavers group</span>
          </div>
          <div class="legend-item row" (click)="copyDemoEidtoClipboard('test.cirtError')">
            <span class="col-3 bold">test.cirtError</span><!--with Compliance Flag 20: CIRT - Enablement Requires CIRT Approval-->
            <span class="dash col-1"></span>
            <span class="col">Disabled asking user to "Please contact CIRT enablement."</span>
          </div>
          <div class="legend-item row">
            <span class="col-3 bold"></span>
            <span class="dash col-1"></span>
            <span class="col key d-flex justify-content-end">Click on EID to search a test case. Press ESC to close modal.</span>
          </div>
        </div>
      </div>
    </div>
    <div id="directions">  
        <p>Welcome to EID Security Compliance Tool. Use this application to remove conditional access(Soft CA, Hard CA, Device CA) by following these instructions:</p>
        <svg *ngIf="!isVisible" (click)="setVisible(true)" (keydown)="handleKeydown($event, true)" tabindex="0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="2 3 18 18" fill="#A100FF"><path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z"/><path fill="none" d="M0 0h24v24H0V0z"/></svg>
        <svg *ngIf="isVisible" (click)="setVisible(false)" (keydown)="handleKeydown($event, false)" tabindex="0" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="2 3 24 24" fill="#A100FF"><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
        <div *ngIf="isVisible">
          <div *ngFor="let instruction of instructions">
            <p class="help-text" [innerHTML]="instruction"></p>
          </div>
        </div>
    </div>
    <div id="app-container">
      <ng-container *ngIf="loggedInUser && loggedInUser.eid && loggedInUser.eid.length > 0 && !showGLRMessage">
        <div class="top-bar-container">
          <input id="search-bar" type='text'  class="form-control short" [(ngModel)]='search' (keyup.enter)="selectEid()" placeholder="{{placeholder}}" title="Search EIDs" maxlength="50" />
          <button type="submit" (click)="selectEid()" class="btn btn-primary btn-search short" tabindex="0" aria-label="Search">search</button>
        </div>
        <div class="alert alert-danger big-alert box-shadow" role="alert" *ngIf="errorMessage"><!--[class.mini-alert]="loggedInUser.eid.length"-->
          <svg class="svg-shadow pointer" (click)="selectEid()" data-toggle="tooltip" data-placement="top" title="Retry Select User" xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#721c24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6 10c0 3.31-2.69 6-6 6s-6-2.69-6-6h2c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4v3L8 7l4-4v3c3.31 0 6 2.69 6 6z" fill-rule="evenodd"/></svg>
          | {{errorMessage}}
        </div>
        <div *ngIf="displaySpinner" class="large-spinner">
          <svg class="large-spinner"><circle cx="120" cy="120" r="108"></circle></svg>
        </div>
        <div id="summary" *ngIf="!displaySpinner && !queued && selectedEid.eid">
          <div class="header key">
            <!-- <div *ngIf="selectedEid.eid" class="searched">
              <input id="search-bar" type='text' maxlength="100" class="form-control short" [(ngModel)]='search' (keyup.enter)="selectEid()"
                placeholder="{{placeholder}}" title="Search EIDs" />
              <button type="submit" (click)="selectEid()" class="btn btn-primary btn-search short"
                aria-label="Search">search</button>
            </div> -->
            <div class="containers-container top-containers-container" aria-label="Top Containers">
              <div class="container-1">
                <img *ngIf="profilePicture" [attr.src]="profilePicture" alt="Profile Picture" id="profile-picture" class="box-shadow"tabindex="0">
                <img *ngIf="!profilePicture" src="../../assets/account_circle_purple_36dp.svg" alt="Profile Picture" id="profile-picture"tabindex="0">
                <span class="name bold" tabindex="0">{{selectedEid.eid}}</span>
              </div>
              <div class="container-2">
                <span>
                  <div *ngIf="selectedEid.passwordless === true">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 2 24 24" width="24px" fill="#4A8244">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                    </svg><span class="bold status-phrase compliant" tabindex="0">Passwordless </span>
                  </div>
                  <div *ngIf="!selectedEid.passwordless && selectedEid.isLocked === false && selectedEid.isLocked !== 'Error'">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 2 24 24" width="24px" fill="#4A8244">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                    </svg><span class="bold status-phrase compliant">Password Status: </span>Secure
                  </div>
                  <div *ngIf="!selectedEid.passwordless && selectedEid.isLocked && selectedEid.isLocked !== 'Error'">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 2 24 24" width="24px" fill="#E32739">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                    </svg>
                    <span class="bold status-phrase non-compliant"  aria-labelledby="password status">Password Status:</span>
                    <!-- <svg class="white-border legend-icon svg-shadow" tabindex="0"role="button" xmlns="http://www.w3.org/2000/svg" width="24" height="24"viewBox="0 0 24 24" fill="#7500C0"><path fill="none" d="M0 0h24v24H0z" /><path  d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z" /></svg> -->
                    <span class="key copyLockedDirections copyPasteContainer" (click)="copyToClipboard('copyLockedDirections')" data-toggle="tooltip" data-placement="top" title="Copy to Clipboard" tabindex="0" aria-labelledby="passwordless">
                      This user is not Passwordless. Have them <a target="_blank" rel="noopener noreferrer" href="https://myid.accenture.com/">reset their password</a> using their personal computer or cell phone.
                    </span>
                  </div>
                  <div *ngIf="selectedEid.isEnabled === false">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 2 24 24" width="24px" fill="#E32739">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
                    </svg><span class="bold status-phrase non-compliant" tabindex="0">EID Disabled</span>
                  </div>
                  <div *ngIf="selectedEid.isEnabled && selectedEid.maMessage.length === 0" class="d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px" fill="#4A8244">
                      <path d="M0 0h24v24H0V0z" fill="none" />
                      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                    </svg><span class="bold status-phrase compliant" tabindex="0">EID Enabled</span>
                  </div>
                </span>
              </div>
              <div class="container-3">
                <div class="bold" *ngIf="selectedEid.isInLeaversGroup">
                  If the employee is facing access issues, please have them send their inquiry to HR.EnablementCenter. The HR Enablement Center will provide a response within 24 hours (except weekends).
                </div>
                <!-- <span class="enable-buttons-container" [class.justify-content-around]="(loggedInUser.level <= 6 || this.checkedADGroup)" [class.justify-content-start]="loggedInUser.level === 7" *ngIf="selectedEid.isEnabled === false"> -->
                <span class="enable-buttons-container" [class.justify-content-around]="(this.checkedADGroup)" *ngIf="selectedEid.isEnabled === false">
                  <span *ngIf="!selectedEid.isEnableable">
                    This EID cannot be enabled using this tool.
                  </span>
                  <ng-container *ngIf="selectedEid.isEnableable">
                    <!-- <div *ngIf="loggedInUser.level <= 6 || this.checkedADGroup" class="skinny-enable-button btn btn-primary" -->
                    <div *ngIf="this.checkedADGroup" tabindex="0" class="skinny-enable-button btn btn-primary"
                      (click)="enable()" (keyup.enter)="enable()"  [class.disabled]="!selectedEid.isEnableable">
                      <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 24 24" width="36" fill="#ffffff">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" />
                      </svg>
                      {{selectedEid.isEnableable ? 'enable' : 'ineligible to be enabled'}}
                    </div>
                    <!-- <span *ngIf="loggedInUser.level === 7" class="level-seven-buttons-container">
                      <span class="d-flex flex-column align-items-baseline">
                        <div class="skinny-enable-button btn btn-primary" (click)="enable()"
                          [class.disabled]="!selectedEid.isEnableable || !emptyObject(this.selectedEid.pendingApprovals)">
                          <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 24 24" width="36" fill="#ffffff">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" />
                          </svg>
                          {{ getFirstButtonText() }}
                        </div>
                        <div class="skinny-enable-button btn btn-primary" (click)="enable()"
                          [class.disabled]="!selectedEid.isEnableable || hasValue(selectedEid.pendingApprovals, loggedInUser.eid)">
                          <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 24 24" width="36" fill="#ffffff">
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path
                              d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" />
                          </svg>
                          {{ getSecondButtonText() }}
                        </div>
                      </span>
                      <span class="key" style="padding: 1rem;">Two Level 7 Managers must approve enabling an EID.</span>
                    </span> -->
                  </ng-container>
                </span>
              </div>
            </div>
          </div>
          <div class="containers-container">
            <div class="userContainer info key bottom-border" aria-label="User Information"> 
              <div class="row" tabindex="0" ><span class="col-4">level: </span><span class="col bold">{{selectedEid.level}}</span></div>
              <div class="row" tabindex="0"><span class="col-4">country: </span><span class="col bold">{{selectedEid.country | lowercase}}</span></div>
              <div class="row"tabindex="0"><span class="col-4">city: </span><span class="col bold">{{selectedEid.city | lowercase}}</span></div>
              <div class="row"tabindex="0"><span class="col-4">location: </span><span class="col bold">{{selectedEid.officeLocation | lowercase}}</span></div>
              <div class="row"tabindex="0"><span class="col-4">People Lead: </span><span class="col bold">{{selectedEid.peopleLead}} (<span *ngIf="selectedEid.peopleLeadLevel !== 'Accenture Leadership'">lvl </span>{{selectedEid.peopleLeadLevel}})</span></div>
              <div class="row"tabindex="0"><span class="col-4">PL of PL: </span><span class="col bold">{{selectedEid.peopleLeadPeopleLead}} (<span *ngIf="selectedEid.peopleLeadPeopleLeadLevel !== 'Accenture Leadership'">lvl </span>{{selectedEid.peopleLeadPeopleLeadLevel}})</span></div>
              <div class="row"tabindex="0"><span class="col-4">Manager: </span><span class="col bold">{{selectedEid.manager}} (<span *ngIf="selectedEid.managerLevel !== 'Accenture Leadership'">lvl </span>{{selectedEid.managerLevel}})</span></div>
              <div class="row"tabindex="0"><span class="col-4">Mgr of Mgr: </span><span class="col bold">{{selectedEid.managerManager}} (<span *ngIf="selectedEid.managerManagerLevel !== 'Accenture Leadership'">lvl </span>{{selectedEid.managerManagerLevel}})</span></div>
              <div class="row"tabindex="0"><span class="col-4">mobile: </span><span class="col bold">{{selectedEid.mobilePhone}}</span></div>
              <div class="row"tabindex="0"><span class="col-4">personnel number: </span><span class="col bold d-flex align-items-center">{{selectedEid.personnelNumber}}</span></div>
              <div class="row"tabindex="0"><span class="col-4">Original Hire Date </span><span class="col bold">{{selectedEid.originalHireDate | date: 'M/d/yyyy'}}</span></div>
              <div class="row"tabindex="0"><span class="col-4">Most Recent Hire Date </span><span class="col bold">{{selectedEid.mostRecentHireDate | date: 'M/d/yyyy'}}</span></div>
              <!-- <div class="row"><span class="col-4">people lead: </span><span class="col bold">{{selectedEid.peopleLead}}</span></div> -->
              <!-- <div class="row"><div class="col device-title">DEVICES</div></div> -->
             </div>
            <div class="right-container">
              <div class="height-container info key d-flex flex-column align-items-left">
                  <div class="status top-half">
                    <!-- <div id="copy-paste-button" (click)="copyToClipboard('top-half')" data-toggle="tooltip" data-placement="top" title="Copy to Clipboard">
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px"
                        fill="#7500C0">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                      </svg>
                   </div> -->
                    <span *ngIf="!selectedEid.isEnabled" class="d-flex flex-column key copyPasteContainer" (click)="copyToClipboard('top-half')" data-toggle="tooltip" data-placement="top" title="Copy to Clipboard">
                      <span *ngIf="selectedEid.complianceFlag && this.checkedADGroup" tabindex="0"> because <span class="non-compliant">{{selectedEid.complianceFlag}}</span><span class="invisible">---</span></span>
                      <span *ngIf="selectedEid.isEnableable" [innerHtml]="getMessage(selectedEid.complianceFlag)" tabindex="0"></span>
                    </span>
                    <div *ngIf="!selectedEid.isEnableable && !selectedEid.isEnabled" id="directions-section" (click)="copyToClipboard('top-half')" data-toggle="tooltip" data-placement="top" title="Copy to Clipboard">
                      <!-- Directions Text -->
                      <span class="key" style="margin-bottom: 1rem;" [innerHtml]="getMessage(selectedEid.complianceFlag)"></span>
                    </div>
                    <div *ngIf="loadingDevices && !getDevicesError" class="d-flex justify-content-center align-items-center" style="margin-top: 2.5rem;">
                      <svg class="spinner">
                        <circle cx="20" cy="20" r="18"></circle>
                      </svg>
                      <span style="margin-left: 1rem;">loading devices...</span>
                    </div>
                    <div *ngIf="selectedEid.pmtMessage.length === 0"  aria-label="Device Information">
                      <table *ngIf="selectedEid.devices && selectedEid.devices.length > 0" id="device-table" class="compliant-table">
                        <tr class="bold">
                          <th  class="string-col" tabindex="0"aria-label="DeviceModel">Device Model</th>
                          <th class="string-col" tabindex="0">Device Name</th>
                          <th class="conditional-access" tabindex="0">PMT Compliance</th>
                          <th class="conditional-access" tabindex="0">Conditional Access</th>
                          <th class="conditional-access"></th>
                        </tr>
                        <tr *ngFor="let device of selectedEid.devices">
                          <td class="string-col" tabindex="0">{{device.pcmodel | lowercase}}</td>
                          <td class="string-col"tabindex="0">{{device.computerName}}</td>
                          <td class="conditional-access">
                            <!-- <span data-toggle="tooltip" data-placement="top" title="Has PMT Compliance"><svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px" fill="#4A8244"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" /></svg></span> -->
                            <svg class="tiny-spinner" *ngIf="device.Compliance === null">
                              <circle cx="10" cy="10" r="6"></circle>
                            </svg>
                            <span data-toggle="tooltip" *ngIf="device.Compliance !== 'Not Compliant'" data-placement="top" title="PMT Compliant" tabindex="0"><svg   xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px"  fill="#4A8244" ><path d="M0 0h24v24H0V0z" fill="none"  /><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" /></svg></span>
                            <span data-toggle="tooltip" *ngIf="device.Compliance === 'Not Compliant'" data-placement="top" title="PMT Non Compliant" tabindex="0"><svg   xmlns="http://www.w3.org/2000/svg" height="24px"  viewBox="0 -1 24 24" width="24px" fill="#E32739"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg></span> 
                          </td>
                          <td class="conditional-access">
                            <svg class="tiny-spinner conditional-access" *ngIf="retreivingCA" data-placement="top">
                              <circle cx="10" cy="10" r="6"></circle>
                            </svg>
                            <span data-toggle="tooltip" *ngIf="device.attribute === 'No CA' || device.attribute === 'Remove CA'; else otherAttributes" data-placement="top" title="No Conditional Access" tabindex="0">  <svg  xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px" fill="#4A8244"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" /></svg></span>
                            <span><ng-template #otherAttributes><span tabindex="0">{{ device.attribute }}</span></ng-template></span>
                          </td>  
                          <td class="conditional-access" data-toggle="tooltip" data-placement="top" title="Enable Individual Device">
                            <div role="button" *ngIf="device.attribute === 'Soft CA' || device.attribute === 'Hard CA' || device.attribute === 'Device CA'" class="mini-enable-button btn btn-primary"
                            (click)="enableDevice(device, selectedEid.eid)" (keyup.enter)="enableDevice(device, selectedEid.eid)"  tabindex="0">
                            <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 24 24" width="20" fill="#ffffff"><path d="M0 0h24v24H0z" fill="none" /> <path  d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z" /> </svg>
                            enable
                          </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <div *ngIf="selectedEid.pmtMessage.length > 0 && !selectedEid.pmtMessage.includes('404')" class="alert alert-danger alert-relative box-shadow">There was an error retrieving device data from PMT.  Please try again later.</div>
                    <div *ngIf="selectedEid.pmtMessage.length > 0 &&  selectedEid.pmtMessage.includes('404')" class="alert alert-danger alert-relative box-shadow">This user does not have any "In-use" devices.<br />Please verify this user has device(s) assigned in Enterprise Asset Management (ServiceNow) with "In Use" status</div>
                    <div *ngIf="selectedEid.maMessage.length > 0" class="alert alert-danger alert-relative box-shadow">There was an error retrieving data from MyAccess. Please try again later.</div>
                    <!-- <div class="alert alert-danger box-shadow" style="margin-top: 1rem;" *ngIf="getDevicesError">
                      <svg class="svg-shadow pointer" (click)="retryGetDevicesForEid(selectedEid.eid)" data-toggle="tooltip" data-placement="top" title="Retry Get User Devices" xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#721c24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6 10c0 3.31-2.69 6-6 6s-6-2.69-6-6h2c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4v3L8 7l4-4v3c3.31 0 6 2.69 6 6z" fill-rule="evenodd"/></svg>
                      | Unable to retrieve user's devices. Please try again later.
                    </div> -->
                    <ng-container *ngIf="selectedEid.devices && selectedEid.devices.length > 0">
                      <div class="device-alerts">
                        <div tabindex="0" class="alert alert-danger alert-relative box-shadow" style="margin-top: 1rem;" *ngIf="getDevicesCAError">
                          <!-- <svg class="svg-shadow pointer" (click)="retryCheckDevicesCA()" data-toggle="tooltip" data-placement="top" title="Retry Check Device Conditional Access" xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#721c24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6 10c0 3.31-2.69 6-6 6s-6-2.69-6-6h2c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4v3L8 7l4-4v3c3.31 0 6 2.69 6 6z" fill-rule="evenodd"/></svg> -->
                          Unable to retrieve Conditional Access status for one or more devices.
                        </div>
                        <div class="alert alert-danger alert-relative box-shadow" style="margin-top: 1rem;" *ngIf="deviceNotFound">
                          <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 -960 960 960" width="18px" fill="#808080"><path d="M440-120v-264L254-197l-57-57 187-186H120v-80h264L197-706l57-57 186 187v-264h80v264l186-187 57 57-187 186h264v80H576l187 186-57 57-186-187v264h-80Z" /></svg>
                           The device was not found in Azure to determine Conditional Access. Please see the <a href='/help'>help page</a> for instructions on how to submit a ticket.
                        </div>
                        <!-- <div class="alert alert-danger alert-relative box-shadow" style="margin-top: 1rem;" *ngIf="(device.Compliance && device.Compliance.error)">
                          <svg class="svg-shadow pointer" (click)="retryDevicePMTCompliance(device)" data-toggle="tooltip" data-placement="top" title="Retry Check Device Compliance" xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#721c24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm6 10c0 3.31-2.69 6-6 6s-6-2.69-6-6h2c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4v3L8 7l4-4v3c3.31 0 6 2.69 6 6z" fill-rule="evenodd"/></svg>
                          | Unable to retrieve PMT Compliance for {{ device.computerName }}.
                        </div> -->
                      </div>
                    </ng-container>
                  </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedEid && selectedEid.isLocked !== undefined && selectedEid.isEnabled !== undefined && ((!selectedEid.isEnabled && selectedEid.isLocked) || (!selectedEid.isEnabled) || (selectedEid.isLocked) || (!selectedEid.isEnabled && selectedEid.isLocked))"
           tabindex="0" class="bold allMessage">
            The user must remediate <u>all</u> Compliance & Password issues in order to be enabled.
          </div>
          <div *ngIf="selectedEid && selectedEid.isEnabled && !selectedEid.isLocked" class="bold allMessage">
            <!-- <span *ngIf="this.checkedADGroup">If the user is still experiencing issues, please reference <a target="_blank" rel="noopener noreferrer" href="https://in.accenture.com/intelligentknowledgeexperience/">KB Article</a>.</span> -->
            <span *ngIf="this.checkedADGroup">If the user is still experiencing issues, please reference the <a target="_blank" rel="noopener noreferrer" href="https://support.accenture.com/kb_view.do?sysparm_article=KB0117730">User Guide</a>.</span>
            <span *ngIf="!this.checkedADGroup">If the user is still experiencing issues, please contact service desk<span *ngIf="checkConditionalAccess(selectedEid.devices)"> or enable individual devices</span>.</span>
          </div>
        </div>
        <div class="big-text row" *ngIf="queued">
          <span class="col-2">
            <svg xmlns="http://www.w3.org/2000/svg" height="240" viewBox="0 0 24 24" width="200" fill="#7500C0"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z"/></svg>
          </span>
          <span class="col d-flex flex-column">
            <span>Enablement of <span class="bold">{{selectedEid.eid}}'s</span> account has been queued.</span>
            <span class="key">Note: it may take up to an hour for this account to be enabled</span>
          </span>
        </div>
      </ng-container>
      <div class="alert alert-danger big-alert box-shadow" *ngIf="showGLRMessage">
        <span class="d-flex flex-column">
          This site is currently unavailable to ASGR users pending GLR review.
        </span>
      </div>
      <div class="alert alert-danger big-alert box-shadow" *ngIf="!loggedInUser || !loggedInUser.eid || loggedInUser.eid.length < 1">
        Could not verify the logged in user.
      </div>
    </div>
  </div>
</div>
