// create class device with properties id, computerName, manufacturer, softConditionalAccess, and hardConditionalAcces. and constructor to initialize the properties
export class Device {
    id: string
    azureActiveDirectoryDeviceId: string
    model: string
    serialNumber: string
    computerName: string
    attribute: any; // null for waiting, true for allowed, false for denied, and { error: boolean } for error
    Compliance: any; // null for waiting, true for allowed, false for denied, and { error: boolean } for error
    constructor(id: string, azureActiveDirectoryDeviceId:string, model: string, computerName: string, serialNumber: string, attribute: any, Compliance: any) {
        this.id = id
        this.azureActiveDirectoryDeviceId = azureActiveDirectoryDeviceId
        this.model = model
        this.computerName = computerName
        this.serialNumber = serialNumber
        this.attribute = attribute
        this.Compliance = Compliance
    }
}
