import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { of, pipe, Observable } from 'rxjs'
import { mergeMap, map, filter, catchError } from 'rxjs/operators'
import { DataService } from '../services/data.service'
import { RebarAuthService } from '../auth/rebar.auth.service'
import { checkAuthorized } from '../utilities/auth-util'

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private dataService: DataService, private rebarAuthService: RebarAuthService) {}

  canActivate(): Observable<boolean> {
    let loggedInUser = this.dataService.getLoggedInUser()
    if (loggedInUser) {
      return of(checkAuthorized(loggedInUser, this.router))
    } else { // else wait for authentication process
      return this.rebarAuthService.authObserver$.pipe(
        // tap(authStatus => console.warn('authObserver: '+authStatus)),
        filter(authStatus => authStatus), // wait for non-falsy authStatus NOTE: this includes false
        mergeMap(
          authStatus => this.dataService.getUserData().pipe(
            filter(res => res?.eid),
            map((user: any) => checkAuthorized(user, this.router)), 
            catchError((error: any) => {
              console.error(error)
              if (error !== true) {
                this.router.navigate(['/no-access'])
                return of(false)
              } else return of(true)
            })
          )
        )
      )
    }
  }
}
