export const environment = {
  production: false,
  providers: 'app',
  environment: "stage",
  version: require('../../package.json').version,
  apiURL: "https://enableeid-api.ciostage.accenture.com",
  config: {
    "msal": {
      "authority": "https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb",
      "clientId": "a6f044ee-7964-458c-a0de-280bba920382",
        "redirectUri": "https://enableeid.ciostage.accenture.com",
        "framework": {
            "protectedResourceMap": [
                [
                    "https://graph.microsoft.com/v1.0/me",
                    [
                        "api://0ff84faf-ec39-4963-a6c0-a5c58ba65c96/Access.All"
                    ]
                ]
            ]
        }
    }
  },
  scopes: {
    'graph': ["User.Read", "User.Read.All", "Group.Read.All"],
    'api': ["api://0ff84faf-ec39-4963-a6c0-a5c58ba65c96/Access.All"]
  },
  minimumLevel: 7
};
