export const complianceFlags = [
    { order: 0, label: "", enableable: false, message: "" },
    { order: 1, label: "Out of Compliance", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 2, label: "Legal Review Pending", enableable: false, message: "Please have the employee contact their HR Partner, Exit Case Manager, or send their inquiry to <a href='mailto:HR.EnablementCenter@accenture.com'>HR.EnablementCenter</a>. The HR Enablement Center will provide a response within 24 hours (except weekends)." },
    { order: 3, label: "Personal Data Stored on Computer", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 4, label: "Security Issue", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 5, label: "Must visit LTS for Password Reset", enableable: false, message: "Please contact your local technology support." },
    { order: 6, label: "Non Compliance - No Symantec Endpoint Protection", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 7, label: "Non Compliance - No ECM Agent", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 8, label: "Non Compliance - Contractor Training", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 9, label: "Reset password due to weak password", enableable: false, message: "Please contact your local technology support." },
    { order: 10, label: "Non Compliance - MPC - Antivirus", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 11, label: "Non Compliance - MPC - Firewall", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 12, label: "Non Compliance - Encryption", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 13, label: "Non Compliance - MPC - P2P", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 14, label: "Non Compliance - Rental LTP EntID Lockout", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 15, label: "Non Compliance - Polling", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 16, label: "Non Compliance - Not Registered for MS Project/Visio", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 17, label: "Non Compliance - Workstation Non Polling", enableable: true, message: ""},//"Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 18, label: "Non Compliance - HR Services - Security Updates", enableable: false, message: "Please have the employee contact their HR Partner, Exit Case Manager, or send their inquiry to <a href='mailto:HR.EnablementCenter@accenture.com'>HR.EnablementCenter</a>. The HR Enablement Center will provide a response within 24 hours (except weekends)." },
    { order: 19, label: "Security Incident not Remediated, Contact IS.Monitoring.T1", enableable: false, message: "Security Incident not Remediated. Please contact <a href='mailto:IS.Monitoring.T1.accenture.com'> IS.Monitoring.T1</a>." },
    { order: 20, label: "CIRT - Enablement Requires CIRT Approval", enableable: false, message: "Please ask the employee to contact <a href='mailto:cirt@accenture.com'>CIRT enablement</a>." },
    { order: 21, label: "Ransomware/WannaCry", enableable: false, message: "Please contact your local technology support." },
    { order: 22, label: "GAIT Workstation Non-Polling", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 23, label: "India Exit Process Related Disablement - CTSD Only", enableable: false, message: "Please have the employee contact their HR Partner, Exit Case Manager, or send their inquiry to <a href='mailto:HR.EnablementCenter@accenture.com'>HR.EnablementCenter</a>. The HR Enablement Center will provide a response within 24 hours (except weekends)." },
    { order: 24, label: "Non Compliance - Patching", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 25, label: "Non Compliance - Avecto", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 26, label: "Non Compliance - Tanium", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 27, label: "Non Compliance - Data Loss Protection", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 28, label: "Non Compliance - Chrome", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 29, label: "Non Compliance - Adobe Reader", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 30, label: "Non Compliance - Adobe Player", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 31, label: "Non Compliance - Pulse Secure", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 32, label: "Non Compliance - Java", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 33, label: "Non Compliance - Must upgrade to SEP14", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 34, label: "Unauthorized Software", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 35, label: "Non Compliance - MacOS", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 36, label: "USA Asset Location Mismatch - EID Lockout", enableable: false, message: "Please contact your local technology support." },
    { order: 37, label: "Non-Compliance - Must Resolve TPM Chip Issue", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 38, label: "Non-Compliance - OS Build", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 39, label: "Expired Exception (Do Not Enable Unless Remediated)", enableable: false, message: "Please contact your Client Account Lead." },
    { order: 40, label: "Non Compliance - MAC Asset", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 41, label: "Need to upgrade to Windows 10", enableable: false, message: "Please contact your local technology support." },
    { order: 42, label: "Must upgrade BIOS", enableable: false, message: "Please contact your local technology support." },
    { order: 43, label: "Must upgrade FNMS agent", enableable: false, message: "Please contact your local technology support." },
    { order: 44, label: "Must upgrade HP Audio Key Logger", enableable: false, message: "Please contact your local technology support." },
    { order: 45, label: "Must upgrade Opera to latest version", enableable: false, message: "Please contact your local technology support." },
    { order: 46, label: "Must upgrade Firefox to latest version", enableable: false, message: "Please contact your local technology support." },
    { order: 47, label: "Asset Reporting to Retired SEPM Server", enableable: false, message: "Please contact your local technology support." },
    { order: 48, label: "Non Compliance - Antivirus", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 49, label: "Non Compliance - Adobe Creative Cloud", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 50, label: "Non Compliance - Accenture Laptop Not Returned", enableable: false, message: "Please contact your local technology support." },
    { order: 51, label: "BitLocker PIN Enablement", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 52, label: "Python Not Compliance", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 53, label: "Pulse Secure Not Compliance", enableable: true, message: "Please have the employee visit <a target='_blank' rel='noopener noreferrer' href='https://in.accenture.com/mycomputer/myequipment-faqs/'>MyDevices FAQ</a> and remediate any non-compliant device(s). If they don't, they will be disabled again." },
    { order: 54, label: "Not found - Call Service Desk", enableable: false, message: "" }
]