import { Component, OnInit, OnDestroy, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { Subscription } from 'rxjs'
import { retryWhen } from 'rxjs/operators'
import { DataService } from '../core/services/data.service'
import { Device } from '../models/device'
import { retryStrategy } from '../core/utilities/helper-functions'

@Component({
  selector: 'app-detailed-devices-table',
  templateUrl: './detailed-devices-table.component.html',
  styleUrls: ['./detailed-devices-table.component.scss']
})
export class DetailedDevicesTableComponent implements OnInit {
  @Input() devices: any

  constructor(public activeModal: NgbActiveModal, private dataService: DataService) { }

  ngOnInit(): void {
  }

  retryDeviceCompliance(device: Device): void {
    device.attribute = null
    this.checkDeviceCompliance(device)
  }

  checkDeviceCompliance(device: Device): void {
    let checkDeviceSubscription: Subscription = this.dataService.checkDevice(device.azureActiveDirectoryDeviceId).pipe(
      retryWhen(error => retryStrategy(error))
    ).subscribe({
      next: (res: any) => {
        if (res === null) {
          device.attribute = 'No CA'
        } else if (res.error) {
          device.attribute = { error: true }
        } else if (res.toLocaleLowerCase().includes('hardca')) {
          device.attribute = 'Hard CA'
        } else if (res.toLocaleLowerCase().includes('softca')) {
          device.attribute = 'Soft CA'
        } else if (res.toLocaleLowerCase().includes('removeca')) {
          device.attribute = 'Remove CA'
        } else if (res.toLocaleLowerCase().includes('eidca')) {
          device.attribute = 'Device CA'
        } else if (res.toLocaleLowerCase().includes('disableeidca')) {
          device.attribute = 'Device CA'
        } else {
          device.attribute = 'No CA'
        }
        checkDeviceSubscription.unsubscribe()
      }, error: (error: any) => {
        console.error(error)
        device.attribute = { error: true }
        checkDeviceSubscription.unsubscribe()
      }
    })
  }

}
