<div class="modal-header d-flex mb-2">
    <h5 class="modal-title d-flex p-2">
        <svg class="operations-border icon-margin" xmlns="http://www.w3.org/2000/svg" height="36px" viewBox="0 0 24 24" width="36px" fill="#A100FF"><path d="M0 0h24v24H0z" fill="none"/><path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z"/></svg>        
        <span>Enable EID</span>
    </h5>
    <button type="button" class="close p-2 ms-auto" (click)="activeModal.close('clear')" tabindex="0" aria-label="Close">
      <span aria-hidden="true"><svg alt="close" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#A100FF"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/><path d="M0 0h24v24H0z" fill="none"/></svg></span>
    </button>
</div>
<form [formGroup]="enableFormGroup" [class.disabled]="updating" id="enableFormGroup">
    <div class="modal-body">
        <div class="alert alert-secondary" role="alert" tabindex="0">All Accenture accounts must comply with <a href="https://policies.accenture.com/policy/0056">Accenture Policy 56 & 57</a>. Please confirm they do so before enabling their account.
            <!-- <span *ngIf="checkConditionalAccess(metaData.selectedEid.devices)"><br/>Check the box next to any devices you would like to enable as well.</span> -->
        </div>
            <div class="row form-row">
                <div class="col">
            <div class="form-group inline checkbox-text">
              <span class="form-text">
                <label for="confirm">I understand the repercussions and would like to enable {{metaData.selectedEid.eid}}.*</label>
              </span>
              <div class="form-group d-flex flex-column" aria-label="confirm" tabindex="0" role="button" (keydown.enter)="select('confirm', true)">
                <input type="checkbox" formControlName="confirm" [(ngModel)]="confirm" name="confirm" autocomplete="off" tabindex="-1">
              </div>
            </div></div>
          </div>
          <div class="form-text form-group"><label for="incident">
            Incident Number associated with this enablement.*
            <input type="text" id="incident" formControlName="incident" [(ngModel)]="incident" class="form-control short" required maxlength="11">
        </label>
        </div>
       
        <!-- <table *ngIf="checkConditionalAccess(metaData.selectedEid.devices)" id="detailed-device-table">
            <tr class="bold">
                <th></th>
                <th>Device Model</th>
                <th>Device Name</th>
                <th>Soft Conditional Access</th>
                <th>Hard Conditional Access</th>
            </tr>
            <tr *ngFor="let device of metaData.selectedEid.devices">
                <td><input *ngIf="device.softConditionalAccess === false || device.hardConditionalAccess === false" type="checkbox" id="confirm-{{device.computerName}}" name="confirm-{{device.computerName}}" (click)="confirmDevice($event, device)" autocomplete="off" tabindex="-1" checked></td>
                <td>{{device.model | lowercase}}</td>
                <td>{{device.computerName | lowercase}}</td>
                <td class="d-flex justify-content-center">
                    <span data-toggle="tooltip" data-placement="top" title="Has Soft Conditional Access"><svg *ngIf="device.softConditionalAccess === true" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px" fill="#4A8244"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" /></svg></span>
                    <span data-toggle="tooltip" data-placement="top" title="Does not have Soft Conditional Access"><svg *ngIf="device.softConditionalAccess === false" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -1 24 24" width="24px" fill="#E32739"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg></span>
                </td>
                <td style="text-align: center;">
                    <span data-toggle="tooltip" data-placement="top" title="Has Hard Conditional Access"><svg *ngIf="device.hardConditionalAccess === true" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="2 -1 24 24" width="24px" fill="#4A8244"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" /></svg></span>
                    <span data-toggle="tooltip" data-placement="top" title="Does not have Hard Conditional Access"><svg *ngIf="device.hardConditionalAccess === false" xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -1 24 24" width="24px" fill="#E32739"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" /></svg></span>
                </td>
            </tr>
        </table> -->
        <div *ngIf="isIncidentFieldInvalid()" class="alert alert-warning alert-dismissible">
            <span>Incident Number must start with 'INC' followed by 8 numbers.</span>
        </div>  
            <ng-container *ngIf="updated && !updating">
            <div  *ngIf="!submissionError" class="alert alert-success" role="alert">
                Enabled {{metaData.selectedEid.eid}}!
            </div>
            <div *ngIf="submissionError" class="alert alert-warning alert-dismissible" role="alert">
                {{submissionError}}
            </div>    
        </ng-container>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" (click)="activeModal.close('cancel')" tabindex="0" aria-label="Cancel">Cancel</button>
        <button type="button" class="btn btn-primary" aria-label="Submit" (click)="enable()" tabindex="0" [disabled]="!enableFormGroup.valid">
        <span *ngIf="!updating">Enable EID</span>
        <span *ngIf="updating">Enabling</span>
        </button>
    </div>
</form>